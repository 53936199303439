<script>
export default {
  name: "ViewCrontab"
}
</script>

<template>
  <div>Hello World</div>

</template>

<style scoped>

</style>